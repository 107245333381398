<template>
  <div class="auth-wrapper">
    <el-card class="auth-card">
      <div class="margin-bottom-20">
        <b>Авторизация</b>
      </div>

      <label
        class="main-label width-100 margin-bottom-20"
      >
        <span class="main-label__title">Выберите магазин</span>

        <el-select
          v-model="selectedShop"
          filterable
          collapse-tags
          placeholder="Выберите магазин"
          @input="selectShop"
        >
          <el-option
            v-for="shop in listShops"
            :key="shop.id"
            :label="shop.name"
            :value="shop.id"
          />
        </el-select>
      </label>

      <el-form
        ref="form"
        :model="form"
        @keyup.enter.native="submit"
      >
        <el-form-item
          prop="login"
          :rules="rules.required"
          :error="validateErrors.login"
        >
          <el-input
            v-model="form.login"
            type="text"
            label="Логин"
            placeholder="Логин"
          />
        </el-form-item>

        <el-form-item
          prop="password"
          :rules="rules.required"
          :error="validateErrors.password"
        >
          <el-input
            v-model="form.password"
            label="Пароль"
            type="password"
            show-password
            placeholder="Пароль"
          />
        </el-form-item>

        <div class="block-buttons">
          <el-button
            class="el-button-h-40 width-100"
            type="primary"
            plain
            @click="submit"
          >
            Войти
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { LIST_SHOPS } from '@/constants/global'

export default {
  name: 'Auth',
  data () {
    return {
      valid: true,
      selectedShop: Number(localStorage.getItem('shopId')) || 1,
      listShops: LIST_SHOPS,
      form: {
        login: '',
        password: ''
      },
      validateErrors: {
        login: null,
        password: null
      },
      rules: {
        required: {
          required: true,
          message: 'Поле не должно быть пустым',
          trigger: 'submit'
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('user', ['auth']),
    async submit () {
      this.$refs.form.validate(async (valid, obj) => {
        if (!valid) {
          // Костыль. Необходим для корректного вывода ошибок
          // Из за того что поля в форме рендерятся динамически
          // появляется проблема с вывыодом ошибок
          Object.keys(obj).forEach((field) => {
            this.validateErrors[field] = obj[field]?.[0]?.message
          })

          return
        }

        const data = {
          login: this.form.login,
          password: this.form.password
        }

        await this.$bus.emit('start-loader')
        await this.auth(data)
        await this.$bus.emit('stop-loader')
      })
    },
    selectShop () {
      localStorage.setItem('shopId', String(this.selectedShop))
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
